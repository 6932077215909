import React, { useContext } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { LoginContext } from "src/interface/contexts/login-context";

interface PrivateRouteProps extends RouteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
}

const PrivateRoute = ({
  component: Component,
  ...rest
}: PrivateRouteProps): JSX.Element => {
  const { login } = useContext(LoginContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        login.isLoggedIn ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default PrivateRoute;
