import React, { PropsWithChildren } from "react";
import { ErrorInterface } from "src/interface/components/error/error-display.interface";

const ErrorDisplay: React.FC<ErrorInterface> = (
  props: PropsWithChildren<ErrorInterface>
): JSX.Element => {
  return <h1>{props.reason}</h1>;
};

export default ErrorDisplay;
