import React, { useState, useContext } from "react";
import Auth from "@aws-amplify/auth";
import { useTranslation } from "react-i18next";
import { User } from "src/data/account.interface";
import { SelectedProjectWithTeam } from "src/interface/components/dialogs/dialogs.interface";
import { SingleSelectedActionType } from "src/interface/reducers/actions.interface";
import { LoginContext } from "src/interface/contexts/login-context";
import { SelectedMembersContext } from "src/interface/contexts/selected-members-context";
import { Account } from "src/data/account.service";
import { HeaderProps } from "src/interface/components/header/header.interface";
import { AppBar, Toolbar, Button } from "@material-ui/core";
import RolesDialog from "src/interface/components/dialogs/RolesDialog";
import AddUserDialog from "src/interface/components/dialogs/AddUserDialog";
import RemoveDialog from "src/interface/components/dialogs/RemoveDialog";
import MatrixSearch from "src/interface/components/matrix-search/MatrixSearch";
import sablonoLogoColor from "src/assets/logo_sablono_color.svg";
import styles from "src/interface/components/header/Header.module.css";

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { t } = useTranslation();
  const {
    members,
    errorAction,
    updateLoading,
    updateAddedMembers,
    updateRemovedMembers,
    updateMembersRoles,
    setMembersToDisplayInMatrix,
    downloadCSV,
  } = props;
  const { login, setLogin } = useContext(LoginContext);
  const [state, dispatch] = useContext(SelectedMembersContext);
  const [isOpenAddUserDialog, setIsOpenAddUserDialog] =
    useState<boolean>(false);
  const [isOpenRemoveDialog, setIsOpenRemoveDialog] = useState<boolean>(false);
  const [isOpenRolesDialog, setIsOpenRolesDialog] = useState<boolean>(false);

  const stateIncludesAuthenticatedUser = state.some(
    (member) => member.user.email === login.authUser
  );

  const logout = async (): Promise<void> => {
    try {
      await Auth.signOut();
      setLogin({ isLoggedIn: false });
    } catch (error) {
      const logoutError = error as string;
      throw new Error(logoutError);
    }
  };

  const toggleAddUserDialog = (): void => {
    setIsOpenAddUserDialog(!isOpenAddUserDialog);
  };

  const toggleRemoveDialog = (): void => {
    setIsOpenRemoveDialog(!isOpenRemoveDialog);
  };

  const toggleRolesDialog = (): void => {
    setIsOpenRolesDialog(!isOpenRolesDialog);
  };

  const changeRoles = async (role: string): Promise<void> => {
    if (!role) return;

    setIsOpenRolesDialog(false);
    updateLoading(true);

    try {
      const { hasFailures, failure } = await Account.changeRoleOfMembers(
        state,
        role
      );

      if (hasFailures) {
        errorAction(failure[0]);
      }
    } finally {
      updateMembersRoles(state, role);
      dispatch({
        type: SingleSelectedActionType.CLEAR,
      });
      updateLoading(false);
    }
  };

  const addUsers = async (
    selectedUsers: User[],
    role: string,
    selectedProjectsWithTeam: SelectedProjectWithTeam[]
  ): Promise<void> => {
    try {
      setIsOpenAddUserDialog(false);
      updateLoading(true);

      await Account.createMemberships(
        selectedUsers,
        role,
        selectedProjectsWithTeam
      );
    } finally {
      updateAddedMembers();
      updateLoading(false);
    }
  };

  const removeMemberships = async (): Promise<void> => {
    try {
      setIsOpenRemoveDialog(false);
      updateLoading(true);

      const { hasFailures, failure } =
        await Account.revokeMembershipsOfUser(state);

      if (hasFailures) {
        errorAction(failure[0]);
      }
    } finally {
      updateRemovedMembers(state);
      dispatch({
        type: SingleSelectedActionType.CLEAR,
      });
      updateLoading(false);
    }
  };

  return (
    <>
      <RolesDialog
        visible={isOpenRolesDialog}
        includesAuthenticatedUser={stateIncludesAuthenticatedUser}
        action={changeRoles}
        cancel={toggleRolesDialog}
      />
      <AddUserDialog
        visible={isOpenAddUserDialog}
        members={members}
        action={addUsers}
        cancel={toggleAddUserDialog}
      />
      <RemoveDialog
        visible={isOpenRemoveDialog}
        includesAuthenticatedUser={stateIncludesAuthenticatedUser}
        action={removeMemberships}
        cancel={toggleRemoveDialog}
      />
      <AppBar position="fixed" className={styles.header}>
        <Toolbar>
          <div className={styles.header__logo}>
            <img src={sablonoLogoColor} alt="Sablono" />
            <span className={styles.header__logo__text}>User Management</span>
          </div>
          <MatrixSearch
            members={members}
            setMembersToDisplayInMatrix={setMembersToDisplayInMatrix}
          />
          <Button
            className={styles.button__roles}
            color={"inherit"}
            size="small"
            variant="contained"
            disableElevation
            onClick={() => toggleRolesDialog()}
            disabled={!state.length}
          >
            {t("dialogs.roles-dialog.change-roles-button")}
          </Button>
          <Button
            className={styles.button__add}
            color={"inherit"}
            size="small"
            variant="contained"
            disableElevation
            onClick={() => toggleAddUserDialog()}
            disabled={!state.length}
          >
            {t("dialogs.add-users-dialog.add-users-button")}
          </Button>
          <Button
            className={styles.button__remove}
            color={"inherit"}
            size="small"
            variant="contained"
            disableElevation
            onClick={() => toggleRemoveDialog()}
            disabled={!state.length}
          >
            {t("dialogs.remove-dialog.remove-button")}
          </Button>
          <Button
            className={styles.button__csv}
            color={"inherit"}
            size="small"
            variant="contained"
            disableElevation
            onClick={() => downloadCSV(state)}
          >
            {t("header.csv-button")}
          </Button>
          <Button
            className={styles.button__logout}
            color={"inherit"}
            size="small"
            variant="contained"
            disableElevation
            onClick={() => logout()}
          >
            {t("header.logout-button")}
          </Button>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
