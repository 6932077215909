import axios, { AxiosResponse } from "axios";
import { PROJECTS_BASE_URL } from "src/data/api/constants";
import { Meta } from "src/data/api/api.interface";

interface Project {
  code: string;
  created_at: string;
  desc: string;
  end_date: string;
  id: string;
  image: {
    id: number;
    url: string;
  };
  inspect_app_config: {
    block_agg_progress: boolean;
    hide_readonly_entities: boolean;
    primary_property: string;
  };
  language: string;
  name: string;
  privileges: number;
  scheduling: {
    mode: string;
    state: string;
  };
  start_date: string;
}

interface ProjectApiResponse {
  meta: Meta;
  projects: Project[];
}

export class ProjectApi {
  public static getAll(): Promise<AxiosResponse<ProjectApiResponse>> {
    return axios.get(PROJECTS_BASE_URL);
  }
}
