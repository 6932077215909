import { Member, Project } from "src/data/account.interface";
import { MatrixMember } from "src/interface/reducers/reducers.interface";
import uniqBy from "lodash/uniqBy";
import orderBy from "lodash/orderBy";

export class MembersMatrixFactory {
  public static getAllProjects(members: Member[]): Project[] {
    const allProjects = members
      .reduce((accumulator: Project[], currentMember) => {
        currentMember.memberships.forEach((membership) => {
          accumulator.push({
            name: membership.project.name,
            id: membership.project.id,
          });
        });

        return uniqBy(accumulator, (project) => project.id);
      }, [])
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    return allProjects;
  }

  public static sortMembers(members: Member[]): Member[] {
    return orderBy(
      members,
      [
        (member) => member.user.company.toLowerCase(),
        (member) => member.user.name.toLowerCase(),
      ],
      ["asc", "asc"]
    );
  }

  public static projectMembersCount(
    members: Member[],
    projectName: string
  ): number {
    return members.filter((member) =>
      member.memberships.some(
        (membership) => membership.project.name === projectName
      )
    ).length;
  }

  /*
   * If a member has a membership that includes the project id, the
   * member is returned as a matrix member with that membership only.
   */
  public static generateMatrixMembersWithMembershipFromProjectId(
    members: Member[],
    projectId: string
  ): MatrixMember[] {
    const isMatrixMember = (item: unknown): item is MatrixMember => !!item;

    return members
      .map((member: Member) => {
        return {
          user: {
            userId: member.user.id,
            userName: member.user.name,
            email: member.user.email,
          },
          membershipOfProject: member.memberships.find(
            (membership) => membership.project.id === projectId
          ),
        };
      })
      .map(({ user, membershipOfProject }) => {
        return (
          membershipOfProject && {
            user,
            memberships: [
              {
                projectId: membershipOfProject.project.id,
                projectName: membershipOfProject.project.name,
                teamId: membershipOfProject.team.id,
                teamName: membershipOfProject.team.name,
                role: membershipOfProject.role.name,
              },
            ],
          }
        );
      })
      .filter(isMatrixMember);
  }

  public static mergeNewSelectionIntoCurrentSelection(
    currentSelection: MatrixMember[],
    addedSelection: MatrixMember[]
  ): MatrixMember[] {
    // Updates relevant reducer state members (if needed) with
    // the bulk selected membership.
    const changeInCurrentSelection = currentSelection
      .map((currentMember) => {
        return {
          addedMember: addedSelection.find(
            (member) => member.user.userId === currentMember.user.userId
          ),
          currentMember,
        };
      })
      .map(({ addedMember, currentMember }) => {
        if (addedMember) {
          const memberHaveBulkSelectedMembership =
            currentMember.memberships.some(
              (membership) =>
                membership.projectId === addedMember.memberships[0].projectId
            );

          if (!memberHaveBulkSelectedMembership) {
            // Membership is not available for member
            currentMember.memberships.push(addedMember.memberships[0]);
          }
        }
        return currentMember;
      });

    // Gets all relevant members for bulk selection that are not
    // already included in the reducer state.
    const membersNotAlreadyInReducerState = addedSelection.filter(
      (addedMember) =>
        !changeInCurrentSelection.some(
          (currentMember) =>
            addedMember.user.userId === currentMember.user.userId
        )
    );

    return [...changeInCurrentSelection, ...membersNotAlreadyInReducerState];
  }

  public static removeMembershipFromCurrentSelectionByProjectId = (
    currentSelection: MatrixMember[],
    projectId: string
  ): MatrixMember[] => {
    return currentSelection
      .map((currentMember) => ({
        user: {
          userId: currentMember.user.userId,
          userName: currentMember.user.userName,
          email: currentMember.user.email,
        },
        memberships: currentMember.memberships.filter(
          (membership) => membership.projectId !== projectId
        ),
      }))
      .filter((currentMember) => currentMember.memberships.length);
  };

  public static getReducerStateMembersByProjectId(
    state: MatrixMember[],
    projectId: string
  ): MatrixMember[] {
    return state.filter((stateMember) =>
      stateMember.memberships.some(
        (membership) => membership.projectId === projectId
      )
    );
  }

  public static updateRemovedMembers(
    members: Member[],
    selectedMembers: MatrixMember[]
  ): Member[] {
    /*
     * This method is called when removing memberships to force the
     * component to re-render and display all members excluding the
     * memberships in selectedMembers without having to make an API
     * call.
     */

    return members
      .map((currentMember) => ({
        memberToUpdate: selectedMembers.find(
          (member) => member.user.userId === currentMember.user.id
        ),
        currentMember,
      }))
      .map(({ memberToUpdate, currentMember }) => {
        if (memberToUpdate) {
          currentMember.memberships = currentMember.memberships.filter(
            (membership) =>
              !memberToUpdate.memberships.some(
                (memberToUpdateMembership) =>
                  memberToUpdateMembership.projectId === membership.project.id
              )
          );
        }

        return {
          user: currentMember.user,
          memberships: currentMember.memberships,
        };
      })
      .filter((member) => member.memberships.length);
  }

  public static updateMembersRoles(
    members: Member[],
    selectedMembers: MatrixMember[],
    role: string
  ): Member[] {
    return members
      .map((currentMember) => ({
        memberToChangeRole: selectedMembers.find(
          (member) => member.user.userId === currentMember.user.id
        ),
        currentMember,
      }))
      .map(({ memberToChangeRole, currentMember }) => {
        if (memberToChangeRole) {
          currentMember.memberships = currentMember.memberships.map(
            (currentMemberMembership) => {
              if (
                memberToChangeRole.memberships.some(
                  (membership) =>
                    membership.projectId === currentMemberMembership.project.id
                )
              ) {
                currentMemberMembership.role.name = role;
                return currentMemberMembership;
              } else {
                return currentMemberMembership;
              }
            }
          );
        }

        return {
          user: currentMember.user,
          memberships: currentMember.memberships,
        };
      });
  }
}
