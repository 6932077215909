import {
  MatrixMember,
  Payload,
} from "src/interface/reducers/reducers.interface";

interface Add {
  type: SingleSelectedActionType.ADD;
  payload: Payload;
}

interface Update {
  type: SingleSelectedActionType.UPDATE;
  payload: Payload;
}

interface Remove {
  type: SingleSelectedActionType.REMOVE;
  payload: Payload;
}

interface Clear {
  type: SingleSelectedActionType.CLEAR;
}

interface Replace {
  type: MultipleSelectedActionType.REPLACE;
  payload: MatrixMember[];
}

export enum SingleSelectedActionType {
  ADD = "add",
  UPDATE = "update",
  REMOVE = "remove",
  CLEAR = "clear",
}

export enum MultipleSelectedActionType {
  REPLACE = "replace",
}

export type SingleSelectedDispatch = Add | Update | Remove | Clear;

export type MultipleSelectedDispatch = Replace;
