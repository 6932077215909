import { Member } from "src/data/account.interface";
import { MemberUserProperties } from "src/interface/components/matrix-search/matrix-search.interface";

export const filterMemberUserPropertyByQuery = (
  members: Member[],
  query: string,
  property: MemberUserProperties,
  exactMatch?: boolean
): Member[] => {
  if (exactMatch) {
    return members.filter((member) => member.user[property] === query);
  }

  return members.filter((member) =>
    member.user[property].toLowerCase().includes(query.toLowerCase())
  );
};
