import config from "../../config";

const ADMIN_PRIVILEGE = 31;
const PROJECTS_BASE_URL = config.api.backendUrl + "/api/projects";
const USERS_BASE_URL = config.api.backendUrl + "/api/users";
const COST_PACKAGES_BASE_URL = config.api.backendUrl + "/api/cost-packages";

export {
  ADMIN_PRIVILEGE,
  PROJECTS_BASE_URL,
  USERS_BASE_URL,
  COST_PACKAGES_BASE_URL,
};
