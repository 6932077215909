import axios, { AxiosResponse } from "axios";
import { PROJECTS_BASE_URL } from "src/data/api/constants";
import { Meta } from "src/data/api/api.interface";

interface Team {
  id: number;
  name: string;
  color: string;
  is_project_team: boolean;
  logo: {
    id: number;
    url: string;
  };
  address: {
    name: string;
    city: string;
    post_code: string;
    country: string;
    street: string;
  };
  contact: {
    email: string;
    phone: string;
    name: string;
  };
}

interface TeamApiResponse {
  meta: Meta;
  teams: Team[];
}

export class TeamApi {
  public static getProjectTeams(
    projectId: string
  ): Promise<AxiosResponse<TeamApiResponse>> {
    return axios.get(`${PROJECTS_BASE_URL}/${projectId}/teams`);
  }
}
