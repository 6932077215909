import axios, { AxiosResponse } from "axios";
import { COST_PACKAGES_BASE_URL } from "src/data/api/constants";
import { Meta } from "src/data/api/api.interface";

interface CostGroups {
  id: number;
  project_id: string;
  name: string;
}

interface CostGroupsApiResponse {
  meta: Meta;
  cost_packages: CostGroups[];
}

interface CostGroupCreated {
  at: string;
  by: {
    company_name: string;
    email: string;
    first_name: string;
    id: string;
    initials: string;
    last_name: string;
    name: string;
  };
}

interface CostGroupMember {
  user_id: string;
  cost_package_id: number;
  role: string;
  created: CostGroupCreated;
}

interface CostGroupsMembersApiResponse {
  meta: Meta;
  cost_package_memberships: CostGroupMember[];
}

interface CreateCostGroupMembershipPayload {
  user_id: string;
  role: string;
}

interface CreatedCostGroupMember {
  cost_package_id: number;
  user_id: string;
  role: string;
  created: CostGroupCreated;
}

export class CostGroupsApi {
  public static getAll(): Promise<AxiosResponse<CostGroupsApiResponse>> {
    return axios.get(COST_PACKAGES_BASE_URL);
  }

  public static getCostGroupMembers(
    costPackageId: number
  ): Promise<AxiosResponse<CostGroupsMembersApiResponse>> {
    return axios.get(`${COST_PACKAGES_BASE_URL}/${costPackageId}/memberships`);
  }

  public static createCostGroupMember(
    costPackageId: number,
    createCostGroupMembershipPayload: CreateCostGroupMembershipPayload
  ): Promise<AxiosResponse<CreatedCostGroupMember>> {
    return axios.post(
      `${COST_PACKAGES_BASE_URL}/${costPackageId}/memberships`,
      createCostGroupMembershipPayload
    );
  }

  public static removeCostGroupMember(
    costPackageId: number,
    userId: string
  ): Promise<AxiosResponse> {
    return axios.delete(
      `${COST_PACKAGES_BASE_URL}/${costPackageId}/memberships/${userId}`
    );
  }
}
