import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { RolesDialogProps } from "src/interface/components/dialogs/dialogs.interface";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  Select,
  MenuItem,
} from "@material-ui/core";
import CustomDialog from "src/interface/components/dialogs/CustomDialog";
import styles from "src/interface/components/dialogs/dialogs.module.css";

const RolesDialog: React.FC<RolesDialogProps> = (props: RolesDialogProps) => {
  const { t } = useTranslation();
  const { visible, includesAuthenticatedUser, action, cancel } = props;
  const [role, setRole] = useState<string>("");

  const roles = {
    admin: t("admin"),
    manager: t("manager"),
    controller: t("controller"),
    inspector: t("inspector"),
    reviewer: t("reviewer"),
  };

  const selectRole = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRole(event.target.value as string);
  };

  const triggerChangeRoles = (): void => {
    action(role);
  };

  return (
    <CustomDialog
      visible={visible}
      actionTitle={t("dialogs.roles-dialog.change-roles-button")}
      action={triggerChangeRoles}
      cancel={cancel}
    >
      <DialogTitle>{t("dialogs.roles-dialog.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("dialogs.roles-dialog.body")}</DialogContentText>
        {includesAuthenticatedUser && (
          <DialogContentText className={styles["roles-dialog__warning"]}>
            {t("dialogs.roles-dialog.warning")}
          </DialogContentText>
        )}
      </DialogContent>
      <Select
        className={styles["roles-dialog__selection"]}
        value={role}
        onChange={selectRole}
      >
        {Object.entries(roles).map((role) => (
          <MenuItem key={role[0]} value={role[0]}>
            {role[1]}
          </MenuItem>
        ))}
      </Select>
    </CustomDialog>
  );
};

export default RolesDialog;
