import React, { useContext, useState, useEffect, useReducer } from "react";
import Auth from "@aws-amplify/auth";
import { LoginContext } from "src/interface/contexts/login-context";
import { SelectedMembersContext } from "src/interface/contexts/selected-members-context";
import { selectedMembersReducer } from "src/interface/reducers/selected-members-reducer";
import { MembersMatrixFactory } from "src/interface/components/members-matrix/members-matrix.service";
import { Account } from "src/data/account.service";
import { Member } from "src/data/account.interface";
import {
  exportUserListFromMembers,
  exportUserListFromMatrixMembers,
} from "src/data/export.service";
import { MatrixMember } from "src/interface/reducers/reducers.interface";
import { ErrorInterface } from "src/interface/components/error/error-display.interface";
import Header from "src/interface/components/header/Header";
import ErrorDisplay from "src/interface/components/error/ErrorDisplay";
import MembersMatrix from "src/interface/components/members-matrix/MembersMatrix";
import LoadingIndicator from "src/interface/components/loading-indicator/LoadingIndicator";
import styles from "src/interface/views/dashboard/Dashboard.module.css";

const Dashboard: React.FC = () => {
  const { setLogin } = useContext(LoginContext);
  const selectedMembersContextProviderValue = useReducer(
    selectedMembersReducer,
    []
  );

  const [error, setError] = useState<ErrorInterface>();
  const [loading, setLoading] = useState<boolean>(false);
  const [members, setMembers] = useState<Member[]>();
  const [membersToDisplayInMatrix, setMembersToDisplayInMatrix] = useState<
    Member[]
  >([]);

  useEffect(() => {
    if (members) return;

    Account.getAllUsers()
      .then((res) => {
        setMembers(res);
        setMembersToDisplayInMatrix(res);
      })
      .catch(async () => {
        // TODO: Error handling
        await Auth.signOut();
        setLogin({ isLoggedIn: false });
      });
  });

  const updateError = (error: ErrorInterface): void => {
    setError(error);
  };

  const updateLoading = (isLoading: boolean): void => {
    setLoading(isLoading);
  };

  const updateAddedMembers = async (): Promise<void> => {
    const members = await Account.getAllUsers();
    setMembers(members);
    setMembersToDisplayInMatrix(members);
  };

  const updateRemovedMembers = (selectedMembers: MatrixMember[]): void => {
    setMembersToDisplayInMatrix(
      MembersMatrixFactory.updateRemovedMembers(
        membersToDisplayInMatrix,
        selectedMembers
      )
    );
    members &&
      setMembers(
        MembersMatrixFactory.updateRemovedMembers(members, selectedMembers)
      );
  };

  const updateMembersRoles = (
    selectedMembers: MatrixMember[],
    role: string
  ): void => {
    setMembersToDisplayInMatrix(
      MembersMatrixFactory.updateMembersRoles(
        membersToDisplayInMatrix,
        selectedMembers,
        role
      )
    );
  };

  const downloadCSV = async (matrixMembers?: MatrixMember[]) => {
    if (!members) return;

    if (matrixMembers && matrixMembers.length) {
      await exportUserListFromMatrixMembers(matrixMembers);
    } else {
      await exportUserListFromMembers(membersToDisplayInMatrix);
    }
  };

  if (error) {
    return <ErrorDisplay {...error} />;
  } else {
    return (
      <SelectedMembersContext.Provider
        value={selectedMembersContextProviderValue}
      >
        <div className={styles.dashboard}>
          <div className={styles.dashboard__header}>
            <Header
              members={members || []}
              updateLoading={updateLoading}
              errorAction={updateError}
              updateAddedMembers={updateAddedMembers}
              updateRemovedMembers={updateRemovedMembers}
              updateMembersRoles={updateMembersRoles}
              setMembersToDisplayInMatrix={setMembersToDisplayInMatrix}
              downloadCSV={downloadCSV}
            />
          </div>
          {members && !loading ? (
            <div className={styles.dashboard__matrix}>
              <MembersMatrix members={membersToDisplayInMatrix} />
            </div>
          ) : (
            <div style={{ backgroundColor: "white" }}>
              <LoadingIndicator />
            </div>
          )}
        </div>
        )
      </SelectedMembersContext.Provider>
    );
  }
};

export default Dashboard;
