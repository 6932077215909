import React, { useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import PrivateRoute from "src/PrivateRoute";
import { LoginContext } from "src/interface/contexts/login-context";

import Login from "src/interface/views/login/Login";
import Dashboard from "src/interface/views/dashboard/Dashboard";

const App: React.FC = () => {
  const [login, setLogin] = useState({ isLoggedIn: false });

  return (
    <LoginContext.Provider value={{ login, setLogin }}>
      <Router>
        <Route exact path="/" component={Login} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
      </Router>
    </LoginContext.Provider>
  );
};

export default App;
