import axios, { InternalAxiosRequestConfig } from "axios";

export const setupAuthHeadersInterceptors = (): number =>
  axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const idToken = sessionStorage.getItem("idToken");

    if (accessToken && idToken && config.headers) {
      config.headers.Authorization = accessToken;
      config.headers.Authentication = idToken;
    }

    return config;
  });
