import { Reducer } from "react";
import {
  SingleSelectedDispatch,
  SingleSelectedActionType,
  MultipleSelectedDispatch,
  MultipleSelectedActionType,
} from "src/interface/reducers/actions.interface";
import { MatrixMember } from "src/interface/reducers/reducers.interface";

export const selectedMembersReducer: Reducer<
  MatrixMember[],
  SingleSelectedDispatch | MultipleSelectedDispatch
> = (selectedMembers, action) => {
  switch (action.type) {
    case MultipleSelectedActionType.REPLACE: {
      return action.payload;
    }
    case SingleSelectedActionType.ADD: {
      return [...selectedMembers, action.payload.selectedMember];
    }
    case SingleSelectedActionType.UPDATE: {
      const removedMember = selectedMembers.filter(
        (selectedMember) =>
          selectedMember.user.userId !==
          action.payload.selectedMember.user.userId
      );

      if (!action.payload.selectedMember.memberships.length) {
        return removedMember;
      } else {
        removedMember.push(action.payload.selectedMember);
        return removedMember;
      }
    }
    case SingleSelectedActionType.REMOVE: {
      return selectedMembers.filter(
        (selectedMember) =>
          selectedMember.user.userId !==
          action.payload.selectedMember.user.userId
      );
    }
    case SingleSelectedActionType.CLEAR: {
      return [];
    }
    default:
      return selectedMembers;
  }
};
