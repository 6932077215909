import {
  MatrixMembership,
  MatrixMemberUser,
} from "src/interface/reducers/reducers.interface";
import {
  SingleSelectedDispatch,
  SingleSelectedActionType,
} from "src/interface/reducers/actions.interface";

export const selectedMemberActionFactory = (
  type: SingleSelectedActionType,
  user: MatrixMemberUser,
  memberships: MatrixMembership[]
): SingleSelectedDispatch => {
  return {
    type,
    payload: {
      selectedMember: {
        user,
        memberships,
      },
    },
  };
};
