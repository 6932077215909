import { Member } from "src/data/account.interface";

export enum SearchFilters {
  PROJECT = "project",
  USERNAME = "username",
  EMAIL = "email",
  COMPANY = "company",
}

export interface MatrixSearchProps {
  members: Member[];
  setMembersToDisplayInMatrix: (members: Member[]) => void;
}

export type MemberUserProperties = "name" | "email" | "company";
