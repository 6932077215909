import { UsersApi } from "src/data/api/users-api";
import { User } from "src/data/account.interface";

export class Users {
  public static async searchUser(query: string): Promise<User[]> {
    try {
      const users = await UsersApi.getKnownUsers(query);

      return users.data.users.map((user) => ({
        id: user.id,
        name: user.name,
        email: user.email,
        company: user.company.name,
      }));
    } catch (err) {
      console.warn(err);
      return [];
    }
  }
}
