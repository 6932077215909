import { Member } from "src/data/account.interface";
import { MatrixMember } from "src/interface/reducers/reducers.interface";
import { MapToCSVFormat } from "src/data/mappers.service";
import { generateCSVData } from "src/data/csv.service";
import { downloadBlob } from "src/data/download.service";

const generateTimeStamp = (): string => {
  return new Date().toLocaleDateString("en-GB").split("/").join("-");
};

export const exportUserListFromMembers = async (
  member: Member[]
): Promise<void> => {
  const formattedMembers = MapToCSVFormat.mapMembers(member);
  const csvData = await generateCSVData(formattedMembers);
  const csvBlob = new Blob([csvData], { type: "text/csv" });

  downloadBlob(csvBlob, `users-${generateTimeStamp()}.csv`);
};

export const exportUserListFromMatrixMembers = async (
  matrixMembers: MatrixMember[]
): Promise<void> => {
  const formattedMatrixMembers = MapToCSVFormat.mapMatrixMembers(matrixMembers);
  const csvData = await generateCSVData(formattedMatrixMembers);
  const csvBlob = new Blob([csvData], { type: "text/csv" });

  downloadBlob(csvBlob, `users-${generateTimeStamp()}.csv`);
};
