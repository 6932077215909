import React from "react";
import { useTranslation } from "react-i18next";
import { RemoveDialogProps } from "src/interface/components/dialogs/dialogs.interface";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import CustomDialog from "src/interface/components/dialogs/CustomDialog";
import styles from "src/interface/components/dialogs/dialogs.module.css";

const RemoveDialog: React.FC<RemoveDialogProps> = (
  props: RemoveDialogProps
) => {
  const { t } = useTranslation();
  const { visible, includesAuthenticatedUser, cancel, action } = props;

  return (
    <CustomDialog
      visible={visible}
      actionTitle={t("dialogs.remove-dialog.proceed-button")}
      action={action}
      cancel={cancel}
    >
      <DialogTitle>{t("dialogs.remove-dialog.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("dialogs.remove-dialog.body")}</DialogContentText>
        {includesAuthenticatedUser && (
          <DialogContentText className={styles["remove-dialog__warning"]}>
            {t("dialogs.remove-dialog.warning")}
          </DialogContentText>
        )}
      </DialogContent>
    </CustomDialog>
  );
};

export default RemoveDialog;
