import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { CostGroupsContext } from "src/interface/contexts/cost-groups-context";
import { Membership } from "src/data/account.interface";
import { CostGroup } from "src/data/cost-groups.interface";
import {
  CostGroupsDialogProps,
  CostGroupRoles,
} from "src/interface/components/dialogs/dialogs.interface";
import { CostGroupsService } from "src/data/cost-groups.service";
import { CostGroupsDialogService } from "src/interface/components/dialogs/dialogs.service";
import { DialogTitle, DialogContent, Button, Divider } from "@material-ui/core";
import ProjectCostGroups from "src/interface/components/dialogs/ProjectCostGroups";
import CustomDialog from "src/interface/components/dialogs/CustomDialog";
import LoadingIndicator from "src/interface/components/loading-indicator/LoadingIndicator";
import styles from "src/interface/components/dialogs/dialogs.module.css";

const CostGroupsDialog: React.FC<CostGroupsDialogProps> = (
  props: CostGroupsDialogProps
) => {
  const { t } = useTranslation();
  const { visible, userId, member, cancel } = props;
  const { allCostGroups, setAllCostGroups } = useContext(CostGroupsContext);
  const [noCostGroupsMessage, setNoCostGroupsMessage] = useState<string>("");
  const [membershipsWithCostGroups, setMembershipsWithCostGroups] = useState<
    Membership[]
  >([]);

  const userProjectsCostGroups = (): CostGroup[] => {
    if (!member) return [];

    const projectIdsWhereTheUserIsMember = member.memberships.map(
      (membership) => membership.project.id
    );

    return allCostGroups.filter((costGroup) =>
      projectIdsWhereTheUserIsMember.includes(costGroup.projectId)
    );
  };

  const changeRoles = (
    userId: string,
    role: CostGroupRoles,
    costGroupsToChangeRoleIn: CostGroup[],
    allCostGroups: CostGroup[]
  ) => {
    CostGroupsDialogService.changeRoleForMultipleCostGroups(
      userId,
      role,
      costGroupsToChangeRoleIn
    ).then(() => {
      setAllCostGroups(
        CostGroupsDialogService.updateRoleForMultipleCostGroups(
          userId,
          role,
          allCostGroups,
          costGroupsToChangeRoleIn.map((costGroup) => costGroup.costGroupId)
        )
      );
    });
  };

  const projectActions = [
    {
      action: (costGroups: CostGroup[]) =>
        changeRoles(userId, CostGroupRoles.MANAGER, costGroups, allCostGroups),
      actionTitle: t("dialogs.cost-groups-dialog.add-manager-all-button"),
    },
    {
      action: (costGroups: CostGroup[]) =>
        changeRoles(userId, CostGroupRoles.VIEWER, costGroups, allCostGroups),
      actionTitle: t("dialogs.cost-groups-dialog.add-reviewer-all-button"),
    },
    {
      action: (costGroups: CostGroup[]) =>
        changeRoles(
          userId,
          CostGroupRoles.NOT_ADDED,
          costGroups,
          allCostGroups
        ),
      actionTitle: t("dialogs.cost-groups-dialog.remove-all-button"),
    },
  ];

  const dialogActions = [
    {
      action: () =>
        changeRoles(
          userId,
          CostGroupRoles.MANAGER,
          userProjectsCostGroups(),
          allCostGroups
        ),
      actionTitle: t("dialogs.cost-groups-dialog.add-manager-all-button"),
    },
    {
      action: () =>
        changeRoles(
          userId,
          CostGroupRoles.VIEWER,
          userProjectsCostGroups(),
          allCostGroups
        ),
      actionTitle: t("dialogs.cost-groups-dialog.add-reviewer-all-button"),
    },
    {
      action: () =>
        changeRoles(
          userId,
          CostGroupRoles.NOT_ADDED,
          userProjectsCostGroups(),
          allCostGroups
        ),
      actionTitle: t("dialogs.cost-groups-dialog.remove-all-button"),
    },
  ];

  useEffect(() => {
    if (member && !allCostGroups.length) {
      CostGroupsService.getAll().then((res) => {
        !res.length
          ? setNoCostGroupsMessage(
              t("dialogs.cost-groups-dialog.no-cost-groups")
            )
          : setAllCostGroups(res);
      });
    }
  }, [member]);

  useEffect(() => {
    if (member && allCostGroups.length && !membershipsWithCostGroups.length) {
      const memberships = allCostGroups.reduce(
        (memberships: Membership[], currentCostGroup: CostGroup) => {
          const costGroupInMembership = member.memberships.find(
            (membership) => currentCostGroup.projectId === membership.project.id
          );

          if (
            costGroupInMembership &&
            // We only want to add the membership once
            // even if it has several cost groups.
            !memberships.some(
              (membership) =>
                membership.project.id === costGroupInMembership.project.id
            )
          ) {
            memberships.push(costGroupInMembership);
          }

          return memberships;
        },
        []
      );

      memberships.length
        ? setMembershipsWithCostGroups(memberships)
        : setNoCostGroupsMessage(
            t("dialogs.cost-groups-dialog.no-cost-groups")
          );
    }
  }, [member, allCostGroups]);

  const getNoCostGroupsMessage = (message: string) => {
    return (
      <p
        className={`${styles["cost-groups-text"]} ${styles["no-cost-groups"]}`}
      >
        {message}
      </p>
    );
  };

  return (
    <CustomDialog
      visible={visible}
      cancel={cancel}
      cancelButtonTitle={t("dialogs.close-button")}
      multipleActions={dialogActions}
      disabledMaxWidth
    >
      <DialogTitle>{t("dialogs.cost-groups-dialog.title")}</DialogTitle>
      <DialogContent>
        {noCostGroupsMessage && !allCostGroups.length && (
          <p>{getNoCostGroupsMessage(noCostGroupsMessage)}</p>
        )}
        {member && allCostGroups.length ? (
          <>
            {membershipsWithCostGroups.length ? (
              <>
                {membershipsWithCostGroups.map((membershipWithCostGroups) => {
                  const costGroupsForProject = allCostGroups.filter(
                    (costGroup) =>
                      costGroup.projectId ===
                      membershipWithCostGroups.project.id
                  );

                  return (
                    <div
                      key={membershipWithCostGroups.project.id}
                      className={`${styles["cost-groups-dialog"]}
                        ${styles["cost-groups-text"]}`}
                    >
                      <div className={styles["cost-groups-dialog__actions"]}>
                        <span
                          className={
                            styles["cost-groups-dialog__actions__project-name"]
                          }
                        >
                          {membershipWithCostGroups.project.name}
                        </span>
                        {projectActions.map((action) => (
                          <Button
                            key={action.actionTitle}
                            className={
                              styles["cost-groups-dialog__actions__button"]
                            }
                            size="small"
                            onClick={() => action.action(costGroupsForProject)}
                          >
                            {action.actionTitle}
                          </Button>
                        ))}
                      </div>
                      <div className={styles["cost-groups-dialog__roles"]}>
                        <span
                          className={
                            styles["cost-groups-dialog__roles__heading"]
                          }
                        >
                          {t("dialogs.cost-groups-dialog.cost-groups-header")}
                        </span>
                        <span
                          className={
                            styles["cost-groups-dialog__roles__heading"]
                          }
                        >
                          {t("dialogs.cost-groups-dialog.roles-manager")}
                        </span>
                        <span
                          className={
                            styles["cost-groups-dialog__roles__heading"]
                          }
                        >
                          {t("dialogs.cost-groups-dialog.roles-reviewer")}
                        </span>
                        <span
                          className={
                            styles["cost-groups-dialog__roles__heading"]
                          }
                        >
                          {t("dialogs.cost-groups-dialog.roles-not-added")}
                        </span>
                      </div>
                      <div
                        className={styles["cost-groups-dialog__roles-groups"]}
                      >
                        <ProjectCostGroups
                          costGroupsForProject={costGroupsForProject}
                          userId={member.user.id}
                        />
                      </div>
                      <Divider></Divider>
                    </div>
                  );
                })}
              </>
            ) : (
              <>{getNoCostGroupsMessage(noCostGroupsMessage)}</>
            )}
          </>
        ) : (
          <>{!noCostGroupsMessage && <LoadingIndicator />}</>
        )}
      </DialogContent>
    </CustomDialog>
  );
};

export default CostGroupsDialog;
