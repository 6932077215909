import React, { memo, useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Member } from "src/data/account.interface";
import { MatrixMembership } from "src/interface/reducers/reducers.interface";
import { SingleSelectedActionType } from "src/interface/reducers/actions.interface";
import { selectedMemberActionFactory } from "src/interface/components/matrix-row/matrix-row.service";
import { MatrixRowProps } from "src/interface/components/matrix-row/matrix-row.interface";
import { TableRow, Checkbox, TableCell } from "@material-ui/core";
import CustomTableCell from "src/interface/components/custom-table-cell/CustomTableCell";
import CostGroupsDialog from "src/interface/components/dialogs/CostGroupsDialog";
import styles from "src/interface/components/matrix-row/matrix-row.module.css";
import cellStyles from "src/interface/components/custom-table-cell/custom-table-cell.module.css";

const tableCellStyles = cellStyles.cell;
const checkboxStyles = styles["matrix-row__checkbox"];

const MatrixRow: React.FC<MatrixRowProps> = (props: MatrixRowProps) => {
  const { t } = useTranslation();
  const { rowIndex, member, allProjects, stateOnBulkSelect, dispatch } = props;

  const [memberships, setMemberships] = useState<MatrixMembership[]>([]);
  const [currentMemberForCostGroups, setCurrentMemberForCostGroups] = useState<
    Member | undefined
  >(undefined);
  const [isOpenCostGroupsDialog, setIsOpenCostGroupsDialog] =
    useState<boolean>(false);

  const id = member.user.id;
  const user = {
    userId: member.user.id,
    userName: member.user.name,
    email: member.user.email,
  };

  useEffect(() => {
    const memberOnBulkSelect = stateOnBulkSelect.filter(
      (state) => state.user.userId === id
    )[0];

    memberOnBulkSelect
      ? setMemberships(memberOnBulkSelect.memberships)
      : setMemberships([]);
  }, [stateOnBulkSelect]);

  const defaultMemberships = useMemo(() => {
    /*
     * The conditional block only gets triggered when the member
     * props is altered, e.g. by being removed from a project or
     * assigned a different role. This is necessary to reset all
     * checkboxes.
     */
    if (memberships.length) {
      setMemberships([]);
    }

    return member.memberships.map((membership) => ({
      projectId: membership.project.id,
      projectName: membership.project.name,
      teamId: membership.team.id,
      teamName: membership.team.name,
      role: membership.role.name,
    }));
  }, [member]);

  const onSelectAll = (): void => {
    if (memberships.length) {
      dispatch(
        selectedMemberActionFactory(SingleSelectedActionType.REMOVE, user, [])
      );
      setMemberships([]);
    } else {
      dispatch(
        selectedMemberActionFactory(
          SingleSelectedActionType.ADD,
          user,
          defaultMemberships
        )
      );
      setMemberships(defaultMemberships);
    }
  };

  const onSelectSingle = (
    projectId: string,
    projectName: string,
    teamId: number,
    teamName: string,
    role: string
  ): void => {
    const membership = {
      projectId,
      projectName,
      teamId,
      teamName,
      role,
    };

    if (memberships.length) {
      if (
        memberships.some((membership) => membership.projectId === projectId)
      ) {
        // Remove membership from current memberships
        const membershipsWithoutRemovedMembership = memberships.filter(
          (membership) => membership.projectId !== projectId
        );

        dispatch(
          selectedMemberActionFactory(
            SingleSelectedActionType.UPDATE,
            user,
            membershipsWithoutRemovedMembership
          )
        );
        setMemberships(membershipsWithoutRemovedMembership);
      } else {
        // Add membership to current memberships
        const membershipsWithAddedMembership = [...memberships, membership];

        dispatch(
          selectedMemberActionFactory(
            SingleSelectedActionType.UPDATE,
            user,
            membershipsWithAddedMembership
          )
        );
        setMemberships(membershipsWithAddedMembership);
      }
    } else {
      // Add membership when no prior memberships
      dispatch(
        selectedMemberActionFactory(SingleSelectedActionType.ADD, user, [
          membership,
        ])
      );
      setMemberships([membership]);
    }
  };

  const allChecked = (): boolean => {
    return memberships.length === defaultMemberships.length;
  };

  const singleChecked = (projectId: string): boolean => {
    return memberships.some((membership) => membership.projectId === projectId);
  };

  const selectionIsIndeterminate = (): boolean => {
    return (
      memberships.length > 0 && memberships.length < defaultMemberships.length
    );
  };

  const closeCostGroupsDialog = (): void => {
    setIsOpenCostGroupsDialog(false);
    setCurrentMemberForCostGroups(undefined);
  };

  const rowBgStyles = rowIndex
    ? rowIndex % 2
      ? cellStyles["cell-body__odd-bg"]
      : cellStyles["cell-body__even-bg"]
    : undefined;

  return (
    <>
      <CostGroupsDialog
        visible={isOpenCostGroupsDialog}
        userId={member.user.id}
        member={currentMemberForCostGroups}
        cancel={closeCostGroupsDialog}
      />
      <TableRow className={rowBgStyles}>
        <CustomTableCell stickyBody index={rowIndex}>
          <div className={styles["matrix-row"]}>
            <Checkbox
              className={checkboxStyles}
              indeterminate={selectionIsIndeterminate()}
              checked={allChecked()}
              onChange={() => onSelectAll()}
            />
            <div className={styles["matrix-row__details"]}>
              <span className={styles["matrix-row__details__user"]}>
                {member.user.name}
              </span>
              <span className={styles["matrix-row__details__email"]}>
                {member.user.email}
              </span>
              <span className={styles["matrix-row__details__projects-count"]}>
                Projects: {member.memberships.length}
                {/* Commented out to prevent access to currently broken functionality for managing cost groups 
                ,{" "}
                <a
                  className={styles["matrix-row__details__cost-groups"]}
                  onClick={() => {
                    setCurrentMemberForCostGroups(member);
                    setIsOpenCostGroupsDialog(true);
                  }}
                >
                  {t("matrix.matrix-row.cost-groups-button")}
                </a> */}
              </span>
            </div>
          </div>
        </CustomTableCell>
        {allProjects.map((project) => (
          <TableCell
            className={`${tableCellStyles} ${styles["matrix-row__project-cell"]}`}
            key={`${member.user.id}-${project.id}`}
          >
            {member.memberships.map((membership) => {
              if (membership.project.id === project.id) {
                return (
                  <div
                    key={membership.project.id}
                    className={styles["matrix-row"]}
                  >
                    <Checkbox
                      className={checkboxStyles}
                      checked={singleChecked(project.id)}
                      onChange={() =>
                        onSelectSingle(
                          project.id,
                          project.name,
                          membership.team.id,
                          membership.team.name,
                          membership.role.name
                        )
                      }
                    />
                    <div className={styles["matrix-row__details"]}>
                      <span className={styles["matrix-row__details__role"]}>
                        {t(`${membership.role.name}`)}
                      </span>
                      <span className={styles["matrix-row__details__team"]}>
                        {t(`${membership.team.name}`)}
                      </span>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </TableCell>
        ))}
      </TableRow>
    </>
  );
};

export default memo(MatrixRow);
