import React, { memo } from "react";
import { CustomTableCellProps } from "src/interface/components/custom-table-cell/custom-table-cell.interface";
import { TableCell } from "@material-ui/core";
import styles from "src/interface/components/custom-table-cell/custom-table-cell.module.css";

const CustomTableCell: React.FC<CustomTableCellProps> = (
  props: CustomTableCellProps
) => {
  const { stickyHead, stickyBody, index, children } = props;

  const cellStyles = styles.cell;
  const cellHeadStyles = stickyHead
    ? styles["sticky-cell-head"]
    : styles["cell-head"];
  const cellBodyStyles = stickyBody
    ? styles["sticky-cell-body"]
    : styles["cell-body"];
  const cellBodyBgStyles = index
    ? index % 2
      ? styles["cell-body__odd-bg"]
      : styles["cell-body__even-bg"]
    : styles["cell-body__even-bg"];

  return (
    <TableCell
      className={`${
        stickyHead || stickyBody
          ? stickyHead
            ? cellHeadStyles
            : cellBodyStyles
          : undefined
      } ${cellBodyBgStyles} ${cellStyles}`}
    >
      {children}
    </TableCell>
  );
};

export default memo(CustomTableCell);
