import React, { useState, useEffect, useContext, FormEvent } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { LoginContext } from "src/interface/contexts/login-context";
import Auth from "@aws-amplify/auth";
import config from "src/config";
import sablonoLogoWhite from "src/assets/logo_sablono_white.png";
import styles from "./Login.module.css";
import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";

const getAuthenticatedUser = async () => {
  return await Auth.currentAuthenticatedUser();
};

const Login: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
): JSX.Element => {
  const parsedURLParam = new URLSearchParams(props.location.search).get(
    "redirect"
  );

  const { login, setLogin } = useContext(LoginContext);
  const [loginError, setLoginError] = useState("");
  const [loginCredentials, setLoginCredentials] = useState({
    username: "",
    password: "",
  });

  useEffect(() => {
    Auth.configure({
      authenticationFlowType: "USER_PASSWORD_AUTH",
      mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID,
      storage: sessionStorage,
    });

    const checkUserSession = async () => {
      try {
        const validSession = await Auth.currentSession();
        if (
          validSession &&
          sessionStorage.getItem("accessToken") &&
          sessionStorage.getItem("idToken")
        ) {
          const user = await getAuthenticatedUser();
          setLogin({ isLoggedIn: true, authUser: user.username });
        }
      } catch (err) {}
    };

    checkUserSession();
  }, [setLogin]);

  const loginUser = (e: FormEvent) => {
    e.preventDefault();

    Auth.signIn(loginCredentials.username, loginCredentials.password)
      .then((res: CognitoUser) => {
        return new Promise((resolve, reject) => {
          res.getSession(
            async (err: Error | null, session: null | CognitoUserSession) => {
              if (err || session === null) {
                return reject(err);
              }

              const user = await getAuthenticatedUser();
              const accessToken = session.getAccessToken().getJwtToken();
              const idToken = session.getIdToken().getJwtToken();
              sessionStorage.setItem("accessToken", accessToken);
              sessionStorage.setItem("idToken", idToken);

              setLogin({ isLoggedIn: true, authUser: user.username });
              resolve(session);
            }
          );
        });
      })
      .catch((err) => {
        setLoginError(err);
      });
  };

  const clearError = () => {
    setLoginError("");
  };

  if (login.isLoggedIn) {
    if (!parsedURLParam) return <Redirect to="/dashboard" />;
    return <Redirect to={parsedURLParam} />;
  }

  return (
    <div className={styles.login}>
      <div className={styles.login__background}></div>
      <div className={styles.login__container}>
        <form
          id="login"
          onSubmit={loginUser}
          className={styles.login__container__form}
        >
          <div className={styles.login__container__form__logo}>
            <img src={sablonoLogoWhite} alt="Sablono logo" />
          </div>
          <label
            htmlFor={"email"}
            className={styles.login__container__form__label}
          >
            Username
          </label>
          <input
            id={"email"}
            type={"text"}
            autoComplete={"email"}
            className={styles.login__container__form__input}
            onFocus={() => clearError()}
            onChange={(e) =>
              setLoginCredentials({
                ...loginCredentials,
                username: e.target.value,
              })
            }
          />
          <label
            htmlFor={"password"}
            className={styles.login__container__form__label}
          >
            Password
          </label>
          <input
            id={"password"}
            className={styles.login__container__form__input}
            onFocus={() => clearError()}
            onChange={(e) =>
              setLoginCredentials({
                ...loginCredentials,
                password: e.target.value,
              })
            }
            type={"password"}
            autoComplete={"current-password"}
          />
          <button
            type={"submit"}
            className={styles.login__container__form__button}
          >
            LOGIN
          </button>
          {loginError && (
            <span className={styles.login__container__form__error}>
              Something went wrong when logging in. Provide a valid username and
              password and try again.
            </span>
          )}
        </form>
      </div>
    </div>
  );
};

export default Login;
