import axios, { AxiosResponse } from "axios";
import { PROJECTS_BASE_URL } from "src/data/api/constants";
import { Meta } from "src/data/api/api.interface";

interface Membership {
  role: {
    id: number;
    mask: number;
    name: string;
  };
  team: {
    color: string;
    has_area_restrictions: boolean;
    id: number;
    is_project_team: boolean;
    name: string;
  };
  user: {
    email: string;
    id: string;
    initials: string;
    name: string;
  };
  removed_at?: string;
}

export interface MembershipApiResponse {
  meta: Meta;
  memberships: Membership[];
}

interface CreateMembershipPayload {
  user_id: string;
  team_id: number;
  role_name: string;
}

interface ChangeRolePayload {
  role_name: string;
  team_id: number;
}

export class MembershipApi {
  public static getById(
    projectId: string
  ): Promise<AxiosResponse<MembershipApiResponse>> {
    return axios.get(`${PROJECTS_BASE_URL}/${projectId}/memberships`);
  }

  public static create(
    projectId: string,
    createMembershipPayload: CreateMembershipPayload
  ): Promise<AxiosResponse<MembershipApiResponse>> {
    return axios.post(
      `${PROJECTS_BASE_URL}/${projectId}/memberships`,
      createMembershipPayload
    );
  }

  public static changeRole(
    projectId: string,
    userId: string,
    changeRolePayload: ChangeRolePayload
  ): Promise<AxiosResponse<Membership>> {
    return axios.put(
      `${PROJECTS_BASE_URL}/${projectId}/memberships/${userId}`,
      changeRolePayload
    );
  }

  public static remove(projectId: string, userId: string): Promise<unknown> {
    return axios.delete(
      `${PROJECTS_BASE_URL}/${projectId}/memberships/${userId}`
    );
  }
}
