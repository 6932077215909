import axios, { AxiosResponse, AxiosError } from "axios";
import { ProjectApi } from "src/data/api/project-api";
import {
  MembershipApi,
  MembershipApiResponse,
} from "src/data/api/membership-api";
import { ADMIN_PRIVILEGE } from "src/data/api/constants";
import {
  Results,
  Failure,
  Success,
  Member,
  Membership,
  MembershipCreateRequest,
  MembershipChangeRequest,
  User,
} from "src/data/account.interface";
import { MatrixMember } from "src/interface/reducers/reducers.interface";
import { SelectedProjectWithTeam } from "src/interface/components/dialogs/dialogs.interface";

export class Account {
  private static parseCompanyName(userName: string): string {
    const regEx = userName.match(/.*\((?<company>.*)\)/);
    return regEx?.groups?.company || "";
  }

  private static parsePromisesToResults(
    res: PromiseSettledResult<unknown>[]
  ): Results<Success, Failure> {
    const results: Results<Success, Failure> = {
      hasFailures: false,
      success: [],
      failure: [],
    };

    return res.reduce(
      (
        results: Results<Success, Failure>,
        promise: PromiseSettledResult<unknown>
      ) => {
        if (promise.status === "rejected") {
          results.hasFailures = true;
          results.failure.push({ reason: promise.reason.toString() });
        } else {
          results.success.push({ value: promise.value });
        }

        return results;
      },
      results
    );
  }

  private static parseToResults(
    res: AxiosResponse<MembershipApiResponse | AxiosError>[]
  ): Results<Success, Failure> {
    const results: Results<Success, Failure> = {
      hasFailures: false,
      success: [],
      failure: [],
    };
    return res.reduce(
      (
        results: Results<Success, Failure>,
        resultFromRequest: AxiosResponse<MembershipApiResponse | AxiosError>
      ) => {
        if (resultFromRequest.status === 400) {
          results.hasFailures = true;
          results.failure.push({
            reason: (resultFromRequest.data as AxiosError).message,
          });
        } else {
          results.success.push({ value: resultFromRequest.statusText });
        }

        return results;
      },
      results
    );
  }

  public static async getAllUsers(): Promise<Member[]> {
    const allUsers: Member[] = [];

    await ProjectApi.getAll().then((res) =>
      Promise.all(
        res.data.projects
          .filter((project) => project.privileges === ADMIN_PRIVILEGE)
          .map((project) =>
            MembershipApi.getById(project.id).then((res) => {
              res.data.memberships.forEach((membership) => {
                // Filters out users that have been deleted.
                if (membership.removed_at) return;

                const membershipData: Membership = {
                  team: { name: membership.team.name, id: membership.team.id },
                  role: { name: membership.role.name, id: membership.role.id },
                  project: { name: project.name, id: project.id },
                };

                const includedUser: Member | undefined = allUsers.find(
                  (member) => member.user.id === membership.user.id
                );

                if (!includedUser) {
                  const company = this.parseCompanyName(membership.user.name);

                  allUsers.push({
                    user: {
                      id: membership.user.id,
                      name: membership.user.name,
                      email: membership.user.email,
                      company,
                    },
                    memberships: [membershipData],
                  });
                } else {
                  includedUser.memberships.push(membershipData);
                }
              });
            })
          )
      )
    );

    return allUsers;
  }

  public static async createMemberships(
    selectedUsers: User[],
    role: string,
    selectedProjectsWithTeam: SelectedProjectWithTeam[]
  ): Promise<Results<Success, Failure>> {
    const membershipsCreateRequests = selectedUsers.reduce(
      (allMemberships: MembershipCreateRequest[], user: User) => {
        const membershipsOfUser = selectedProjectsWithTeam.map((project) => ({
          projectId: project.projectId,
          userId: user.id,
          teamId: project.team.id,
          role,
        }));

        return allMemberships.concat(membershipsOfUser);
      },
      []
    );

    return Account.createMembershipsRequests(membershipsCreateRequests);
  }

  private static async createMembershipsRequests(
    membershipsCreateRequests: MembershipCreateRequest[]
  ): Promise<Results<Success, Failure>> {
    const requests: AxiosResponse<MembershipApiResponse | AxiosError>[] = [];

    for (const membershipCreateRequest of membershipsCreateRequests) {
      try {
        const res = await MembershipApi.create(
          membershipCreateRequest.projectId,
          {
            user_id: membershipCreateRequest.userId,
            team_id: membershipCreateRequest.teamId,
            role_name: membershipCreateRequest.role,
          }
        );

        requests.push(res);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          requests.push(err.response);
        }
      }
    }

    return Account.parseToResults(requests);
  }

  public static async changeRoleOfMembers(
    selectedMembers: MatrixMember[],
    role: string
  ): Promise<Results<Success, Failure>> {
    const memberships = selectedMembers.reduce(
      (memberships: MembershipChangeRequest[], member: MatrixMember) => {
        const membershipsOfMember: MembershipChangeRequest[] =
          member.memberships.map((membershipOfMember) => {
            return {
              projectId: membershipOfMember.projectId,
              teamId: membershipOfMember.teamId,
              role,
              userId: member.user.userId,
            };
          });
        return memberships.concat(membershipsOfMember);
      },
      []
    );

    return Account.changeRoleOfMemberships(memberships);
  }

  public static async changeRoleOfMemberships(
    memberships: MembershipChangeRequest[]
  ): Promise<Results<Success, Failure>> {
    const requests = memberships.map((membership) =>
      MembershipApi.changeRole(membership.projectId, membership.userId, {
        role_name: membership.role,
        team_id: membership.teamId,
      })
    );

    const changeUsersRolesRes: PromiseSettledResult<unknown>[] =
      await Promise.allSettled(requests);

    return this.parsePromisesToResults(changeUsersRolesRes);
  }

  public static async revokeMembershipsOfUser(
    selectedMembers: MatrixMember[]
  ): Promise<Results<Success, Failure>> {
    const removeUsersRes: PromiseSettledResult<unknown>[] =
      await Promise.allSettled(
        selectedMembers.reduce(
          (apiCalls: Promise<unknown>[], selectedMember: MatrixMember) => {
            const nextApiCalls: Promise<unknown>[] =
              selectedMember.memberships.map((membership) =>
                MembershipApi.remove(
                  membership.projectId,
                  selectedMember.user.userId
                )
              );

            return apiCalls.concat(nextApiCalls);
          },
          []
        )
      );

    return this.parsePromisesToResults(removeUsersRes);
  }
}
