import React, { useState, useContext, useEffect, useMemo } from "react";
import { SelectedMembersContext } from "src/interface/contexts/selected-members-context";
import { useTranslation } from "react-i18next";
import { Project, User } from "src/data/account.interface";
import {
  AddUserDialogProps,
  EmailSearchOptions,
  ProjectAndTeams,
  SelectedProjectWithTeam,
} from "src/interface/components/dialogs/dialogs.interface";
import { Team } from "src/data/team.service";
import { Users } from "src/data/users.service";
import {
  getSelectedProjectsWithTeams,
  onSelectProjectsWithTeam,
} from "src/interface/components/dialogs/dialogs.service";
import { MembersMatrixFactory } from "src/interface/components/members-matrix/members-matrix.service";
import { Options } from "react-select";
import {
  DialogTitle,
  DialogContent,
  Tooltip,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import AsyncSelect from "react-select/async";
import CustomDialog from "src/interface/components/dialogs/CustomDialog";
import styles from "src/interface/components/dialogs/dialogs.module.css";

const AddUserDialog: React.FC<AddUserDialogProps> = (
  props: AddUserDialogProps
) => {
  const { t } = useTranslation();
  const { visible, members, action, cancel } = props;

  const [state] = useContext(SelectedMembersContext);

  const [allProjects, setAllProjects] = useState<Project[]>([]);
  const [projectsWithTeams, setProjectsWithTeams] = useState<ProjectAndTeams[]>(
    []
  );
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [selectedRole, setSelectedRole] = useState<string>("");
  const [selectedProjectsWithTeam, setSelectedProjectsWithTeam] = useState<
    SelectedProjectWithTeam[]
  >([]);

  useEffect(() => {
    setAllProjects(MembersMatrixFactory.getAllProjects(members));
  }, [members]);

  const projectsTeams: Promise<ProjectAndTeams[]> | undefined = useMemo(() => {
    /*
     * Memorizes the teams api request so that
     * new teams requests only are made if the
     * available projects changes.
     */
    if (allProjects.length > 0) {
      return Team.getAllTeamsForProjects(allProjects);
    }
  }, [allProjects.length]);

  useEffect(() => {
    if (projectsTeams !== undefined) {
      projectsTeams.then((res) => {
        setProjectsWithTeams(getSelectedProjectsWithTeams(res, state));
      });
    }
  }, [state]);

  const roles = [
    { buttonLabel: "PA", buttonTooltip: t("admin"), buttonValue: "admin" },
    { buttonLabel: "PM", buttonTooltip: t("manager"), buttonValue: "manager" },
    {
      buttonLabel: "PC",
      buttonTooltip: t("controller"),
      buttonValue: "controller",
    },
    {
      buttonLabel: "SI",
      buttonTooltip: t("inspector"),
      buttonValue: "inspector",
    },
    {
      buttonLabel: "PR",
      buttonTooltip: t("reviewer"),
      buttonValue: "reviewer",
    },
  ];

  const clearSelections = (): void => {
    setSelectedUsers([]);
    setSelectedRole("");
    setSelectedProjectsWithTeam([]);
  };

  const triggerAddUsers = (): void => {
    clearSelections();
    action(selectedUsers, selectedRole, selectedProjectsWithTeam);
  };

  const cancelAddUsers = (): void => {
    clearSelections();
    cancel();
  };

  const emailSearchOptions = (
    query: string,
    callback: (emailSearchOptions: EmailSearchOptions[]) => void
  ) => {
    Users.searchUser(query).then((res) =>
      callback(res.map((user) => ({ value: user, label: user.name })))
    );
  };

  const updateSelectedUsers = (options: Options<EmailSearchOptions>): void => {
    const users: User[] = options.map(
      (option: EmailSearchOptions) => option.value
    );
    setSelectedUsers(users);
  };

  return (
    <CustomDialog
      visible={visible}
      cancel={cancelAddUsers}
      disabledActionButton={
        !selectedUsers.length ||
        !selectedRole.length ||
        selectedProjectsWithTeam.length !== projectsWithTeams.length
      }
      actionTitle={t("dialogs.add-users-dialog.add-users-button")}
      action={triggerAddUsers}
    >
      <div className={styles["add-users-dialog"]}>
        <DialogTitle>{t("dialogs.add-users-dialog.title")}</DialogTitle>
        <DialogContent>
          <AsyncSelect
            placeholder={t("dialogs.add-users-dialog.search-field-placeholder")}
            isMulti
            loadOptions={emailSearchOptions}
            onChange={updateSelectedUsers}
          />
          <div className={styles["add-users-dialog__roles"]}>
            {roles.map((role) => (
              <Tooltip key={role.buttonLabel} title={role.buttonTooltip}>
                <Button
                  className={
                    selectedRole === role.buttonValue
                      ? styles[
                          `add-users-dialog__button-${role.buttonLabel}-selected`
                        ] +
                        " " +
                        styles["add-users-dialog__button-selected"]
                      : styles[`add-users-dialog__button-${role.buttonLabel}`]
                  }
                  size="large"
                  onClick={() => setSelectedRole(role.buttonValue)}
                >
                  {role.buttonLabel}
                </Button>
              </Tooltip>
            ))}
          </div>
          <div className={styles["add-users-dialog__projects"]}>
            {projectsWithTeams &&
              projectsWithTeams.map((projectWithTeams) => (
                <div key={projectWithTeams.project.id}>
                  <span className={styles["add-users-dialog__projects__name"]}>
                    {projectWithTeams.project.name}
                  </span>
                  <div className={styles["add-users-dialog__projects__team"]}>
                    <FormControl
                      className={
                        styles["add-user-dialog__projects__team__field"]
                      }
                      variant="outlined"
                    >
                      <InputLabel>{`${t(
                        "dialogs.add-users-dialog.select-team-placeholder"
                      )}  ${projectWithTeams.project.name}`}</InputLabel>
                      <Select
                        label={`${t(
                          "dialogs.add-users-dialog.select-team-placeholder"
                        )}  ${projectWithTeams.project.name}`}
                        value={
                          selectedProjectsWithTeam.find(
                            (selectedProjectWithTeam) =>
                              selectedProjectWithTeam.projectId ===
                              projectWithTeams.project.id
                          )?.team.name || ""
                        }
                      >
                        {projectWithTeams.teams.map((team) => (
                          <MenuItem
                            onClick={() =>
                              setSelectedProjectsWithTeam(
                                onSelectProjectsWithTeam(
                                  projectWithTeams.project.id,
                                  team.id,
                                  team.name,
                                  selectedProjectsWithTeam
                                )
                              )
                            }
                            key={team.id}
                            value={team.name}
                          >
                            {t(team.name)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              ))}
          </div>
        </DialogContent>
      </div>
    </CustomDialog>
  );
};

export default AddUserDialog;
