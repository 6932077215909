import { Member, Project, Team, User } from "src/data/account.interface";
import { CostGroup } from "src/data/cost-groups.interface";

export interface CustomDialogProps {
  children: unknown;
  visible: boolean;
  actionTitle?: string;
  disabledActionButton?: boolean;
  disabledMaxWidth?: boolean;
  action?: () => void;
  multipleActions?: {
    action: () => void;
    actionTitle: string;
  }[];
  cancel: () => void;
  cancelButtonTitle?: string;
}

export interface RemoveDialogProps {
  visible: boolean;
  includesAuthenticatedUser: boolean;
  cancel: () => void;
  action: () => void;
}

export interface RolesDialogProps {
  visible: boolean;
  includesAuthenticatedUser: boolean;
  cancel: () => void;
  action: (role: string) => void;
}

export interface AddUserDialogProps {
  visible: boolean;
  members: Member[];
  cancel: () => void;
  action: (
    selectedUsers: User[],
    role: string,
    selectedProjectsWithTeam: SelectedProjectWithTeam[]
  ) => void;
}

export interface EmailSearchOptions {
  value: User;
  label: string;
}

export interface ProjectAndTeams {
  project: Project;
  teams: Team[];
}

export interface SelectedProjectWithTeam {
  projectId: string;
  team: Team;
}

export interface CostGroupsDialogProps {
  visible: boolean;
  userId: string;
  member: Member | undefined;
  cancel: () => void;
}

export interface CostGroupsDialogRolesProps {
  costGroupsForProject: CostGroup[];
  userId: string;
}

export interface CostGroupRole {
  costGroupId: number;
  costGroupName: string;
  role: string | undefined;
}

export enum CostGroupRoles {
  MANAGER = "manager",
  VIEWER = "viewer",
  NOT_ADDED = "notAdded",
}
