import React, { useContext } from "react";
import { CostGroupsContext } from "src/interface/contexts/cost-groups-context";
import {
  CostGroupsDialogRolesProps,
  CostGroupRole,
  CostGroupRoles,
} from "src/interface/components/dialogs/dialogs.interface";
import { CostGroupsDialogService } from "src/interface/components/dialogs/dialogs.service";
import { Radio } from "@material-ui/core";
import styles from "src/interface/components/dialogs/dialogs.module.css";

const ProjectCostGroups: React.FC<CostGroupsDialogRolesProps> = (
  props: CostGroupsDialogRolesProps
) => {
  const { costGroupsForProject, userId } = props;

  const { allCostGroups, setAllCostGroups } = useContext(CostGroupsContext);

  const costGroupRolesForUser =
    CostGroupsDialogService.getCostGroupRolesForUser(
      costGroupsForProject,
      userId
    );

  const handleRadioButtonChange =
    (costGroupId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const roleToAssign = event.target.value as CostGroupRoles;
      const userAlreadyHaveRoleAssigned: boolean = costGroupRolesForUser.some(
        (costGroupRoleForUser) =>
          costGroupRoleForUser.costGroupId === costGroupId &&
          costGroupRoleForUser.role !== undefined
      );

      CostGroupsDialogService.changeRole(
        userId,
        roleToAssign,
        costGroupId,
        userAlreadyHaveRoleAssigned
      ).then(() =>
        setAllCostGroups(
          CostGroupsDialogService.updateRole(
            userId,
            roleToAssign,
            allCostGroups,
            costGroupId
          )
        )
      );
    };

  return (
    <>
      <div className={styles["project-cost-groups"]}>
        {costGroupRolesForUser.map((costGroup: CostGroupRole) => (
          <div
            key={costGroup.costGroupId}
            className={styles["project-cost-groups__content"]}
          >
            <span className={styles["project-cost-groups__content__name"]}>
              {costGroup.costGroupName}
            </span>
            <div className={styles["project-cost-groups__content__name__role"]}>
              <Radio
                size="small"
                onChange={handleRadioButtonChange(costGroup.costGroupId)}
                value={CostGroupRoles.MANAGER}
                checked={costGroup.role === "manager"}
              />
            </div>
            <div className={styles["project-cost-groups__content__name__role"]}>
              <Radio
                size="small"
                onChange={handleRadioButtonChange(costGroup.costGroupId)}
                value={CostGroupRoles.VIEWER}
                checked={costGroup.role === "viewer"}
              />
            </div>
            <div className={styles["project-cost-groups__content__name__role"]}>
              <Radio
                size="small"
                onChange={handleRadioButtonChange(costGroup.costGroupId)}
                value={CostGroupRoles.NOT_ADDED}
                checked={costGroup.role === undefined}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ProjectCostGroups;
