import { TeamApi } from "src/data/api/team-api";
import { Project } from "src/data/account.interface";
import { ProjectAndTeams } from "src/interface/components/dialogs/dialogs.interface";

export class Team {
  public static async getAllTeamsForProjects(
    projects: Project[]
  ): Promise<ProjectAndTeams[]> {
    const allTeamsForProjects = [];

    for (const project of projects) {
      const { data } = await TeamApi.getProjectTeams(project.id);

      allTeamsForProjects.push({
        project,
        teams: data.teams.map((team) => ({
          id: team.id,
          name: team.name,
        })),
      });
    }

    return allTeamsForProjects;
  }
}
