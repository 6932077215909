import React from "react";
import { useTranslation } from "react-i18next";
import { CustomDialogProps } from "src/interface/components/dialogs/dialogs.interface";
import { Dialog, DialogActions, Button } from "@material-ui/core";
import styles from "src/interface/components/dialogs/dialogs.module.css";

const CustomDialog: React.FC<CustomDialogProps> = (
  props: CustomDialogProps
) => {
  const { t } = useTranslation();
  const {
    visible,
    actionTitle,
    action,
    multipleActions,
    cancel,
    cancelButtonTitle,
    children,
    disabledActionButton,
    disabledMaxWidth,
  } = props;

  return (
    <Dialog open={visible} maxWidth={disabledMaxWidth && false}>
      {children}
      <DialogActions>
        {action && (
          <Button disabled={disabledActionButton} onClick={() => action()}>
            {actionTitle}
          </Button>
        )}
        {multipleActions && (
          <div className={styles["custom-dialog__multiple-action-buttons"]}>
            {multipleActions.map((action) => (
              <Button key={action.actionTitle} onClick={() => action.action()}>
                {action.actionTitle}
              </Button>
            ))}
          </div>
        )}
        <Button onClick={() => cancel()}>
          {cancelButtonTitle || t("dialogs.cancel-button")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
