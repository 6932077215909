import axios, { AxiosResponse } from "axios";
import { USERS_BASE_URL } from "src/data/api/constants";
import { Meta } from "src/data/api/api.interface";

interface User {
  id: string;
  name: string;
  email: string;
  initials: string;
  first_name: string;
  last_name: string;
  company: {
    id: number;
    name: string;
  };
}

interface UsersApiResponse {
  meta: Meta;
  users: User[];
}

export class UsersApi {
  public static getKnownUsers(
    query: string
  ): Promise<AxiosResponse<UsersApiResponse>> {
    return axios.get(USERS_BASE_URL + `?search=${encodeURIComponent(query)}`);
  }
}
