import { MatrixMember } from "src/interface/reducers/reducers.interface";
import { CostGroup } from "src/data/cost-groups.interface";
import {
  ProjectAndTeams,
  SelectedProjectWithTeam,
  CostGroupRole,
  CostGroupRoles,
} from "src/interface/components/dialogs/dialogs.interface";
import { CostGroupsService } from "src/data/cost-groups.service";
import { flatten } from "lodash";

export const getSelectedProjectsWithTeams = (
  projectsWithTeams: ProjectAndTeams[],
  matrixMembers: MatrixMember[]
): ProjectAndTeams[] => {
  const projects = flatten(
    matrixMembers.map((matrixMember) => matrixMember.memberships)
  );

  return projectsWithTeams.filter((projectWithTeams) =>
    projects.some(
      (project) => project.projectId === projectWithTeams.project.id
    )
  );
};

export const onSelectProjectsWithTeam = (
  projectId: string,
  teamId: number,
  teamName: string,
  selectedProjectsWithTeam: SelectedProjectWithTeam[]
): SelectedProjectWithTeam[] => {
  const selectedProjectWithTeamIndex: number =
    selectedProjectsWithTeam.findIndex(
      (selectedProjectWithTeam) =>
        selectedProjectWithTeam.projectId === projectId
    );
  const isAlreadySelected = selectedProjectWithTeamIndex >= 0;

  if (!isAlreadySelected) {
    selectedProjectsWithTeam.push({
      projectId,
      team: { id: teamId, name: teamName },
    });
    return [...selectedProjectsWithTeam];
  } else {
    selectedProjectsWithTeam[selectedProjectWithTeamIndex].team.id = teamId;
    return [...selectedProjectsWithTeam];
  }
};

export class CostGroupsDialogService {
  private static isMemberInCostGroup = (
    costGroup: CostGroup,
    userId: string
  ): boolean => {
    return costGroup.members.some((member) => member.userId === userId);
  };

  public static getCostGroupRolesForUser = (
    costGroups: CostGroup[],
    userId: string
  ): CostGroupRole[] => {
    return costGroups.map((costGroup) => {
      const isUserMemberOfCostGroup = costGroup.members.find(
        (costGroupMember) => costGroupMember.userId === userId
      );

      if (isUserMemberOfCostGroup) {
        return {
          costGroupId: costGroup.costGroupId,
          costGroupName: costGroup.name,
          role: isUserMemberOfCostGroup.role,
        };
      } else {
        return {
          costGroupId: costGroup.costGroupId,
          costGroupName: costGroup.name,
          role: undefined,
        };
      }
    });
  };

  public static async changeRole(
    userId: string,
    roleToAssign: string,
    costGroupId: number,
    userAlreadyHaveRole: boolean
  ): Promise<void> {
    if (userAlreadyHaveRole) {
      switch (roleToAssign) {
        case CostGroupRoles.MANAGER:
        case CostGroupRoles.VIEWER:
          CostGroupsService.changeUserRole(costGroupId, userId, roleToAssign);
          break;
        case CostGroupRoles.NOT_ADDED:
          CostGroupsService.removeUser(costGroupId, userId);
          break;
      }
    } else if (
      !userAlreadyHaveRole &&
      roleToAssign !== CostGroupRoles.NOT_ADDED
    ) {
      CostGroupsService.addUser(costGroupId, userId, roleToAssign);
    }
  }

  public static async changeRoleForMultipleCostGroups(
    userId: string,
    roleToAssign: string,
    costGroups: CostGroup[]
  ): Promise<void> {
    for (const costGroup of costGroups) {
      const userAlreadyHaveRole: boolean =
        CostGroupsDialogService.isMemberInCostGroup(costGroup, userId);

      await CostGroupsDialogService.changeRole(
        userId,
        roleToAssign,
        costGroup.costGroupId,
        userAlreadyHaveRole
      );
    }
  }

  public static updateRole(
    userId: string,
    roleToAssign: string,
    costGroups: CostGroup[],
    costGroupId: number
  ): CostGroup[] {
    return costGroups.map((costGroup) => {
      if (costGroup.costGroupId !== costGroupId) {
        return costGroup;
      }

      const costGroupMemberIndex: number = costGroup.members.findIndex(
        (member) => member.userId === userId
      );

      if (
        costGroupMemberIndex === -1 &&
        roleToAssign !== CostGroupRoles.NOT_ADDED
      ) {
        // Add user with role to cost group
        costGroup.members.push({
          userId,
          role: roleToAssign,
        });
      } else if (roleToAssign === CostGroupRoles.NOT_ADDED) {
        // Remove user from cost group
        costGroup.members.splice(costGroupMemberIndex, 1);
      } else {
        // Change user role in cost group
        costGroup.members[costGroupMemberIndex].role = roleToAssign;
      }

      return costGroup;
    });
  }

  public static updateRoleForMultipleCostGroups(
    userId: string,
    roleToAssign: string,
    costGroups: CostGroup[],
    costGroupIds: number[]
  ): CostGroup[] {
    /*
     * Iterates over array of cost group ids and calls updateRoles to
     * to update the role of the provided user for a cost group of the
     * given id.
     */
    return costGroupIds.reduce(
      (costGroups: CostGroup[], currentCostGroupId: number) => {
        return CostGroupsDialogService.updateRole(
          userId,
          roleToAssign,
          costGroups,
          currentCostGroupId
        );
      },
      costGroups
    );
  }
}
