import { Member } from "src/data/account.interface";
import { MatrixMember } from "src/interface/reducers/reducers.interface";
import { CSVData } from "src/data/csv.interface";
import orderBy from "lodash/orderBy";

export class MapToCSVFormat {
  private static sortCSVData(csvData: CSVData[]): CSVData[] {
    return orderBy(
      csvData,
      [
        (data) => data.user.toLowerCase(),
        (data) => data.role.toLowerCase(),
        (data) => data.project.toLowerCase(),
      ],
      ["asc", "asc", "asc"]
    );
  }

  public static mapMembers(members: Member[]): CSVData[] {
    const csvData: CSVData[] = [];

    members.forEach((member) => {
      member.memberships.forEach((membership) => {
        csvData.push({
          user: member.user.name,
          email: member.user.email,
          project: membership.project.name,
          role: membership.role.name,
        });
      });
    });

    return this.sortCSVData(csvData);
  }

  public static mapMatrixMembers(matrixMembers: MatrixMember[]): CSVData[] {
    const csvData: CSVData[] = [];

    matrixMembers.forEach((matrixMember) => {
      matrixMember.memberships.forEach((membership) => {
        csvData.push({
          user: matrixMember.user.userName,
          email: matrixMember.user.email,
          project: membership.projectName,
          role: membership.role,
        });
      });
    });

    return this.sortCSVData(csvData);
  }
}
