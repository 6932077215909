import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Member } from "src/data/account.interface";
import {
  SearchFilters,
  MatrixSearchProps,
} from "src/interface/components/matrix-search/matrix-search.interface";
import { filterMemberUserPropertyByQuery } from "src/interface/components/matrix-search/matrix-search.service";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import styles from "src/interface/components/matrix-search/matrix-search.module.css";

const matrixSearchStyles = styles["matrix-search"];
const matrixSearchFilterStyles = styles["matrix-search__filter"];
const matrixSearchInputStyles = styles["matrix-search__input"];

const selectFieldLabel = "Select search filter";
const textFieldLabel = "Search";

const MatrixSearch: React.FC<MatrixSearchProps> = (
  props: MatrixSearchProps
) => {
  const { t } = useTranslation();
  const { members, setMembersToDisplayInMatrix } = props;

  const [searchFilter, setSearchFilter] = useState<string>(
    SearchFilters.USERNAME
  );

  const selectSearchFilter = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearchFilter(event.target.value as string);
  };

  const search = (event: React.ChangeEvent<{ value: unknown }>) => {
    const query = event.target.value as string;
    let result: Member[] = [];

    if (searchFilter === SearchFilters.PROJECT) {
      result = members
        .map((member) => {
          return {
            user: {
              id: member.user.id,
              name: member.user.name,
              email: member.user.email,
              company: member.user.company,
            },
            memberships: member.memberships.filter((membership) =>
              membership.project.name
                .toLowerCase()
                .includes(query.toLowerCase())
            ),
          };
        })
        .filter((member) => member.memberships.length);
    }

    if (searchFilter === SearchFilters.USERNAME) {
      result = filterMemberUserPropertyByQuery(members, query, "name");
    }

    if (searchFilter === SearchFilters.EMAIL) {
      result = filterMemberUserPropertyByQuery(members, query, "email");
    }

    if (searchFilter === SearchFilters.COMPANY) {
      result = filterMemberUserPropertyByQuery(members, query, "company");
    }

    return setMembersToDisplayInMatrix(result);
  };

  const searchFilters = {
    project: t(`matrix.search-filters.${SearchFilters.PROJECT}`),
    username: t(`matrix.search-filters.${SearchFilters.USERNAME}`),
    email: t(`matrix.search-filters.${SearchFilters.EMAIL}`),
    company: t(`matrix.search-filters.${SearchFilters.COMPANY}`),
  };

  return (
    <div className={matrixSearchStyles}>
      <FormControl
        className={matrixSearchFilterStyles}
        variant="outlined"
        size="small"
      >
        <InputLabel>{selectFieldLabel}</InputLabel>
        <Select
          label={selectFieldLabel}
          value={searchFilter}
          onChange={selectSearchFilter}
        >
          {Object.entries(searchFilters).map((searchFilter) => (
            <MenuItem key={searchFilter[0]} value={searchFilter[0]}>
              {searchFilter[1]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        className={matrixSearchInputStyles}
        label={textFieldLabel}
        onChange={search}
        variant="outlined"
        size="small"
      />
    </div>
  );
};

export default MatrixSearch;
