import React, { useEffect, useContext, useMemo, useState } from "react";
import { SelectedMembersContext } from "src/interface/contexts/selected-members-context";
import { CostGroupsContext } from "src/interface/contexts/cost-groups-context";
import { MembersMatrixFactory } from "src/interface/components/members-matrix/members-matrix.service";
import { MembersMatrixProps } from "src/interface/components/members-matrix/members-matrix.interface";
import { Project } from "src/data/account.interface";
import { CostGroup } from "src/data/cost-groups.interface";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Checkbox,
} from "@material-ui/core";
import CustomTableCell from "src/interface/components/custom-table-cell/CustomTableCell";
import MatrixRow from "src/interface/components/matrix-row/MatrixRow";
import rowStyles from "src/interface/components/matrix-row/matrix-row.module.css";
import { MatrixMember } from "src/interface/reducers/reducers.interface";
import {
  MultipleSelectedActionType,
  SingleSelectedActionType,
} from "src/interface/reducers/actions.interface";
import styles from "src/interface/components/members-matrix/members-matrix.module.css";

const tableHeadStyles = styles["members-matrix__table-head"];
const tableHeadContentStyles = styles["members-matrix__table-head__content"];
const tableHeadDetailsStyles =
  styles["members-matrix__table-head__content__details"];
const userCountStyles =
  styles["members-matrix__table-head__content__details__user-count"];
const tableBodyStyles = styles["members-matrix__table-body"];
const checkboxStyles = rowStyles["matrix-row__checkbox"];

const MembersMatrix: React.FC<MembersMatrixProps> = (
  props: MembersMatrixProps
) => {
  const { members } = props;

  const allProjects: Project[] = useMemo(
    () => MembersMatrixFactory.getAllProjects(members),
    [members]
  );

  const [reducerState, dispatch] = useContext(SelectedMembersContext);
  const [reducerStateCopy, setReducerStateCopy] = useState<MatrixMember[]>([]);
  const [allCostGroups, setAllCostGroups] = useState<CostGroup[]>([]);

  useEffect(() => {
    // Clear any bulk or single selected members whenever
    // the members props is updated to avoid accidentally
    // performing actions on previous selections that are
    // not showing up in the search result.

    if (reducerState.length) {
      dispatch({ type: SingleSelectedActionType.CLEAR });
      setReducerStateCopy([]);
    }
  }, [members]);

  const isChecked = (projectId: string): boolean => {
    const defaultMembersByMembership: number =
      MembersMatrixFactory.generateMatrixMembersWithMembershipFromProjectId(
        members,
        projectId
      ).length;

    const reducerStateMembersByProjectId: number =
      MembersMatrixFactory.getReducerStateMembersByProjectId(
        reducerState,
        projectId
      ).length;

    return (
      defaultMembersByMembership === reducerStateMembersByProjectId &&
      defaultMembersByMembership + reducerStateMembersByProjectId !== 0
    );
  };

  const isIndeterminate = (projectId: string): boolean => {
    const defaultMembersByMembership: number =
      MembersMatrixFactory.generateMatrixMembersWithMembershipFromProjectId(
        members,
        projectId
      ).length;

    const reducerStateMembersByProjectId: number =
      MembersMatrixFactory.getReducerStateMembersByProjectId(
        reducerState,
        projectId
      ).length;

    return (
      reducerStateMembersByProjectId < defaultMembersByMembership &&
      reducerStateMembersByProjectId > 0
    );
  };

  const bulkSelectProject = (projectId: string): MatrixMember[] => {
    const defaultMembersFilteredByMembership =
      MembersMatrixFactory.generateMatrixMembersWithMembershipFromProjectId(
        members,
        projectId
      );

    const stateWithBulkSelectedMembership =
      MembersMatrixFactory.mergeNewSelectionIntoCurrentSelection(
        reducerState,
        defaultMembersFilteredByMembership
      );

    dispatch({
      type: MultipleSelectedActionType.REPLACE,
      payload: stateWithBulkSelectedMembership,
    });
    return stateWithBulkSelectedMembership;
  };

  const bulkDeselectProject = (projectId: string): MatrixMember[] => {
    const stateWithBulkDeselectedMembership =
      MembersMatrixFactory.removeMembershipFromCurrentSelectionByProjectId(
        reducerState,
        projectId
      );

    dispatch({
      type: MultipleSelectedActionType.REPLACE,
      payload: stateWithBulkDeselectedMembership,
    });
    return stateWithBulkDeselectedMembership;
  };

  const onBulkSelect = (projectId: string): void => {
    const projectIsBulkSelected = isChecked(projectId);

    if (projectIsBulkSelected) {
      const bulkDeselectState = bulkDeselectProject(projectId);
      setReducerStateCopy(bulkDeselectState);
    } else {
      const bulkSelectState = bulkSelectProject(projectId);
      setReducerStateCopy(bulkSelectState);
    }
  };

  return (
    <CostGroupsContext.Provider value={{ allCostGroups, setAllCostGroups }}>
      <TableContainer>
        <Table stickyHeader>
          <TableHead className={tableHeadStyles}>
            <TableRow>
              <CustomTableCell stickyHead key={0}>
                Member
              </CustomTableCell>
              {allProjects.map((project, index) => (
                <CustomTableCell key={index + 1}>
                  <div className={tableHeadContentStyles}>
                    <Checkbox
                      className={checkboxStyles}
                      onChange={() => onBulkSelect(project.id)}
                      checked={isChecked(project.id)}
                      indeterminate={isIndeterminate(project.id)}
                    />
                    <div className={tableHeadDetailsStyles}>
                      <span>{project.name}</span>
                      <span className={userCountStyles}>
                        Users:{" "}
                        {MembersMatrixFactory.projectMembersCount(
                          members,
                          project.name
                        )}
                      </span>
                    </div>
                  </div>
                </CustomTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={tableBodyStyles}>
            {MembersMatrixFactory.sortMembers(members).map((member, index) => {
              return (
                <MatrixRow
                  key={member.user.id}
                  rowIndex={index}
                  member={member}
                  allProjects={allProjects}
                  stateOnBulkSelect={reducerStateCopy}
                  dispatch={dispatch}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </CostGroupsContext.Provider>
  );
};

export default MembersMatrix;
