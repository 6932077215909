import { CostGroupsApi } from "src/data/api/cost-groups-api";
import { CostGroup, CostGroupMember } from "src/data/cost-groups.interface";

export class CostGroupsService {
  private static async getMembersOf(
    costGroupId: number
  ): Promise<CostGroupMember[]> {
    try {
      const costGroupMembersRes =
        await CostGroupsApi.getCostGroupMembers(costGroupId);

      return costGroupMembersRes.data.cost_package_memberships.map(
        (costGroupMember) => ({
          userId: costGroupMember.user_id,
          role: costGroupMember.role,
        })
      );
    } catch {
      return [];
    }
  }

  public static async getAll(): Promise<CostGroup[]> {
    const costGroups: CostGroup[] = [];
    const costPackagesRes = await CostGroupsApi.getAll();
    const costPackages = costPackagesRes.data.cost_packages;

    for (const costPackage of costPackages) {
      costGroups.push({
        projectId: costPackage.project_id,
        costGroupId: costPackage.id,
        name: costPackage.name,
        members: await CostGroupsService.getMembersOf(costPackage.id),
      });
    }

    return costGroups;
  }

  public static async addUser(
    costGroupId: number,
    userId: string,
    role: string
  ): Promise<void> {
    const costGroupMembershipPayload = {
      user_id: userId,
      role,
    };

    await CostGroupsApi.createCostGroupMember(
      costGroupId,
      costGroupMembershipPayload
    );
  }

  public static async removeUser(
    costGroupId: number,
    userId: string
  ): Promise<void> {
    await CostGroupsApi.removeCostGroupMember(costGroupId, userId);
  }

  public static async changeUserRole(
    costGroupId: number,
    userId: string,
    role: string
  ): Promise<void> {
    await CostGroupsService.removeUser(costGroupId, userId);
    await CostGroupsService.addUser(costGroupId, userId, role);
  }
}
